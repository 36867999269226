/**======================================================================
=========================================================================
Template Name: Dashboard Bootstrap 5 Admin Template
Author: Rajodiya Infotech
Support: [support link]
File: style.css
=========================================================================
=================================================================================== */
// theme font
// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";
$menu-styler: 360px;
$pct-colors: ();
$pct-colors: map-merge(
  (
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
  ),
  $pct-colors
);

.pct-customizer {
  position: fixed;
  right: -#{$menu-styler};
  top: 160px;
  z-index: 1025;
  transition: all 0.15s ease-in-out;

  &.active {
    right: 0;

    .pct-c-btn {
      padding-right: 0;
    }
  }

  .pct-c-btn {
    display: block;
    position: absolute;
    right: 100%;
    top: 0;
    transition: all 0.15s ease-in-out;

    .btn {
      padding: 17.5px 17.5px;
      display: block;
      border-radius: 8px 0 0 8px;
      box-shadow: 0 9px 9px -1px rgba(81, 69, 157, 0.3);

      + .btn {
        margin-top: 8px;
      }
    }
  }

  .pct-c-content {
    width: $menu-styler;
    position: relative;
    top: 0;
    right: 0;
    background: #fff;
    overflow: hidden;
    border-radius: 0 0 0 4px;
    box-shadow: -9px 0 18px -1px rgba(69, 90, 100, 0.1);

    .pct-header {
      padding: 20px 30px;
      border-bottom: 1px solid $border-color;
    }

    .pct-body {
      padding: 20px 30px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.doc-img,
.theme-color {
  display: block;
  position: relative;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;

  > a {
    position: relative;
    width: 35px;
    height: 25px;
    border-radius: 3px;
    display: inline-block;
    background: $body-bg;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);

    + a {
      margin-left: 5px;
    }

    span {
      width: 100%;
      position: absolute;

      &:after,
      &:before {
        content: "";
        height: 100%;
        position: absolute;
      }

      &:before {
        width: 32%;
        left: 0;
        background: $sidebar-background;
      }

      &:after {
        width: 70%;
        right: 0;
        background: $body-bg;
      }
    }

    > span:nth-child(1) {
      height: 40%;
      top: 0;

      &:after {
        background: $header-background;
      }
    }

    > span:nth-child(2) {
      height: 66%;
      bottom: 0;
    }
  }

  &.header-color {
    @each $color, $value in $pct-colors {
      > a {
        &[data-value="bg-#{$color}"] {
          > span:nth-child(1) {
            &:after {
              background: $value;
            }
          }
        }
      }
    }
  }

  &.brand-color {
    @each $color, $value in $pct-colors {
      > a {
        &[data-value="bg-#{$color}"] {
          > span:nth-child(1) {
            &:before {
              background: $value;
            }
          }
        }
      }
    }
  }
  $i: 1;
  &.themes-color {
    @each $name, $value in $themes-colors {
      > a {
        &[data-value="theme-#{$i}"] {
          background: map-get($value, "primary-grd"), map-get($value, "primary");
        }
      }
      $i: $i + 1;
    }
  }
}

.doc-img {
  > a {
    width: 100px;
    height: 65px;
  }
}

// header color varient Start
$header-c-background: #1c232f;
$header-c-color: #fff;

.dash-header {
  &[class*="bg-"],
  &.dark-header {
    box-shadow: none;
    background: $header-c-background;
    color: $header-c-color;

    @media (min-width: 1025px) {
      .dash-head-link {
        color: $header-c-color;

        i {
          color: $header-c-color;
        }

        .material-icons-two-tone {
          background-color: $header-c-color;
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
          color: $header-c-color;
          background: transparentize($white, 0.85);

          .material-icons-two-tone {
            background-color: $header-c-color;
          }
        }

        .user-desc {
          color: transparentize($header-c-color, 0.3);
        }
      }
    }
  }
}

// header color varient end
// sidebar color varient Start
$sidebar-c-background: #fff !default;
$sidebar-c-color: #333333 !default;
$sidebar-c-active-color: $primary !default;
$sidebar-c-caption: #333333 !default;
$sidebar-c-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08) !default;

.dash-sidebar {
  &.light-sidebar {
    background: $sidebar-c-background;
    box-shadow: $sidebar-c-shadow;

    .dash-caption {
      color: $sidebar-c-caption;
    }

    .dash-navbar {
      > .dash-item {
        > .dash-link {
          border-radius: 12px;
          margin-left: 15px;
          margin-right: 15px;
          padding: 7px 10px 7px 7px;
        }
      }
    }

    .dash-link {
      color: $sidebar-c-color;
      font-size: 14px;

      .dash-micon {
        background-color: $sidebar-c-background;
        box-shadow: -3px 4px 23px rgba(0, 0, 0, 0.1);
        i,
        svg {
          color: #525b69;
          stroke: #525b69;
          fill: #f2f2f2;
        }

        .material-icons-two-tone {
          background-color: #525b69;
        }
      }
      .dash-arrow {
        margin-top: 7px;
      }
      &:active,
      &:focus,
      &:hover {
        color: $sidebar-c-active-color;
      }
    }
    .dash-submenu{
      .dash-link {
        .dash-arrow {
          margin-top: 2px;
        } 
      }
    }
    .dash-item.active,
    .dash-item:hover {
      > .dash-link {
        color: $sidebar-c-active-color;
        i {
          color: $sidebar-c-active-color;
        }

        .dash-micon i.material-icons-two-tone {
          background-color: $sidebar-c-active-color;
        }
      }
    }

    .dash-navbar {
      > .dash-item {
        &.active {
          > .dash-link {
            font-weight: 700;
          }
        }
        &.active,
        &:focus,
        &:hover {
          > .dash-link {
            box-shadow: -6px 11px 19px rgba(0, 0, 0, 0.04);
            background: $sidebar-c-background;
            // background: #dfe7f1;
            color: $sidebar-c-color;
          }
        }
      }
    }
  }
}

.minimenu {
  .dash-sidebar.light-sidebar {
    .dash-navbar {
      > .dash-item {
        > .dash-link {
          padding: 20px 25px;
          margin-left: 0;
          margin-right: 0;
          border-radius: 0;
        }

        &.active,
        &:focus,
        &:hover {
          > .dash-link {
          }
        }
      }
    }

    .dash-item .dash-item:hover > .dash-link {
      background: transparent;
    }

    .dash-submenu {
      box-shadow: $sidebar-c-shadow;
      background: $sidebar-c-background;
    }
  }
}

.dash-horizontal {
  .topbar {
    &.light-sidebar {
      background: $sidebar-c-background;
      box-shadow: $sidebar-c-shadow;

      .dash-link {
        color: $sidebar-c-color;

        &:active,
        &:focus,
        &:hover {
          color: $sidebar-c-active-color;
        }
      }
    }
  }
}

.auth-wrapper {
  ~ .pct-customizer {
    display: none;
  }
}
// sidebar color varient end
